<template>
	<div id="pageLink">
        <a-modal title="选择链接" v-model:visible="dialogVisible" :footer="null" @cancel="show = false" width="1000px">
            <a-tabs v-model:activeKey="activeName" @change="handleClick">
                <a-tab-pane key="1" tab="链接地址">
                    <a-button style="margin-top: 10px;" v-for="(item,index) in shop_btn" :key="index" @click="selectPath(index)">{{item.name}}</a-button>
                    <div style="margin-top: 100px;text-align: right;">
                        <a-button @click="dialogVisible = false">取 消</a-button>
                        <a-button type="primary" @click="sureSelect">确 定</a-button>
                    </div>
                </a-tab-pane>
                <a-tab-pane key="2" tab="商品">
                    <a-table size="small" :scroll="{y: 400}" :pagination="false" :data-source="list" row-key="id" :columns="[
                        {title:'ID',dataIndex:'id'},
                        {title:'封面',dataIndex:'cover',slots:{customRender:'cover'}},
                        {title:'商品名称',dataIndex:'goods_name',width:'500px'},
                        {title:'价格',dataIndex:'price'},
                        {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
                    ]" >
                        <template #cover="{record}">
                            <a-image :src="record.cover" style="width:40px;height:40px"></a-image>
                        </template>
                        <template #action="{record}">
                            <a-button @click="selectPath(record.id)">选择</a-button>
                        </template>
                    </a-table>
                </a-tab-pane>
                <a-tab-pane key="3" tab="租地">
                    <a-table size="small" :scroll="{y: 400}" :pagination="false" :data-source="list" row-key="id" :columns="[
                        {title:'ID',dataIndex:'id'},
                        {title:'封面',dataIndex:'cover',slots:{customRender:'cover'}},
                        {title:'土地名称',dataIndex:'land_name',width:'500px'},
                        {title:'最低价格',dataIndex:'price'},
                        {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
                    ]" >
                        <template #cover="{record}">
                            <a-image :src="record.cover" style="width:40px;height:40px"></a-image>
                        </template>
                        <template #action="{record}">
                            <a-button @click="selectPath(record.id)">选择</a-button>
                        </template>
                    </a-table>
                </a-tab-pane>
                <a-tab-pane key="4" tab="认养">
                    <a-table size="small" :scroll="{y: 400}" :pagination="false" :data-source="list" row-key="id" :columns="[
                        {title:'ID',dataIndex:'id'},
                        {title:'封面',dataIndex:'cover',slots:{customRender:'cover'}},
                        {title:'认养名称',dataIndex:'name',width:'500px',slots:{customRender:'name'}},
                        {title:'最低价格',dataIndex:'price'},
                        {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
                    ]" >
                        <template #cover="{record}">
                            <a-image :src="record.cover" style="width:40px;height:40px"></a-image>
                        </template>
                        <template #name="{record}">
                            {{record.name}}
                            第{{record.numbers}}期
                        </template>
                        <template #action="{record}">
                            <a-button @click="selectPath(record.id)">选择</a-button>
                        </template>
                    </a-table>
                </a-tab-pane>
                <a-tab-pane key="5" tab="自定义页面">
                    <a-table size="small" :scroll="{y: 400}" :pagination="false" :data-source="list" row-key="id" :columns="[
                        {title:'ID',dataIndex:'id'},
                        {title:'名称',dataIndex:'page_name',width:'500px'},
                        {title:'是否默认',dataIndex:'is_default',slots:{customRender:'is_default'}},
                        {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
                    ]" >
                        <template #is_default="{record}">
                            <a-tag :color="record.is_default == 1 ?'#00CC66':'#999'">{{ record.is_default == 1 ?'默认':'否'}}</a-tag>   
                        </template>
                        <template #action="{record}">
                            <a-button @click="selectPath(record.id)">选择</a-button>
                        </template>
                    </a-table>
                </a-tab-pane>
            </a-tabs>
            <div class="pager" v-if="activeName !=1">
                <a-pagination
                    :default-current="page"
                    :total="pageCount"
                    @change="getDataList"
                />
            </div>
        </a-modal>
	</div>
</template>

<script>
import { $post } from '@/api/http'
export default{
	name:'pageLink',
	data(){
		return{
			dialogVisible:false,
			activeName:'1',
			shop_btn:[
				{name:'商城列表',path:'/serch_list'},
				{name:'土地列表',path:'/leasedland_details'},
				{name:'认养列表',path:'/adopt_index'},
				{name:'领券中心',path:'/coupon'},
				{name:'我的土地',path:'/my_land'},
				{name:'我的认养',path:'/adopt_order'},
				{name:'常见问题',path:'/help'},
				{name:'购物车',path:'/shoppingcart'},
				{name:'资讯列表',path:'/news_index'},
				{name:'商户列表',path:'/list'}
			],
			path:'',
			page:1,
			pageCount:0,
			limit:10,
			list:[]
		}
	},
	props:{
		isShow:{
			type:Boolean
		},
	},
	watch: {
		
		isShow: {
			deep: true,  // 深度监听
			handler(newVal,oldVal) {
				this.dialogVisible = newVal
			}
		},
		
		dialogVisible:{
			deep: true,  // 深度监听
			handler(newVal,oldVal) {
				if( !newVal ){
					this.$emit('surePathSelect',false)
				}
			}
		},
	},
	methods:{
		handleClick(e){
			this.page =1
			if( this.activeName != 1 ){
				this.getDataList(1);	
			}
		},
		selectPath(id){
			if( this.activeName == 1 ){
				this.path =this.shop_btn[id].path	
				return
			}
			if( this.activeName == 2 ){
				this.path = '/orderdetail?id='+id
			}
			if( this.activeName == 3 ){
				this.path = '/leasedland?land_id='+id
			}
			if( this.activeName == 4 ){
				this.path = '/adopt_details?adopt_id='+id
			}
			if( this.activeName ==5 ){
				this.path = '/?id='+id
			}
			this.sureSelect();
		},
		
		sureSelect(){
			this.$emit('surePathSelect',this.path )	
		},
		
		getDataList(page){
			let t = this ,{ limit,activeName } =t
			let data ={
				page,
				limit
			}
			let url = ''
			
			if( activeName == 2 ){
				url='getGoodsList'
			}
			if( activeName == 3 ){
				url='getLandList'
			}
			if( activeName == 4 ){
				url='getAdoptionList'
			}
			if( activeName == 5 ){
				url='getWebsitePageList'
			}
			$post(url,data).then(res=>{
				t.list = res.data;
				t.pageCount = res.page.totalCount
				t.page = res.page.currentPage
			},err=>{
				console.log(err);
			})
		},
		
		changePage(page){
			this.page = page
			if( this.activeName==2 ){
				this.getDataList(page)
			}
		}
	}
}
</script>

<style>
	.link-row .el-table td, .link-row .el-table th{
		padding: 0 !important;
	}
</style>
