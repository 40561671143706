<template>
    <div id="Topbar">
		<!-- 样式一 -->
		<div class="topbarlist topbara" v-if="topbarList.website_hander_type == 1" @click="changeCurrent">
			<div class="header">
			    <div class="header_wd">
			        <div class="header_pd">
			            <div>
			                <div>您好！</div>
			                <div>请登录</div>
			                <div>免费注册</div>
			            </div>
			            <div>
			                <div>首页</div>
			                <div>我的订单</div>
			                <div class="cart">购物车</div>
			                <!-- <div class="topbar_phone" style="position: relative;">
			                    手机版
			                    <div class="phone">
			                        <div class="phone_img">
			                            <img :src="topbarList.code" />
			                            <p>(手机扫码下载)</p>
			                        </div>
			                    </div>
			                </div> -->
			                <!-- <div>分销中心</div> -->
			                <div>新闻资讯</div>
			                <!-- <div>商家入驻</div> -->
			            </div>
			        </div>
			    </div>
			</div>
			
			<div class="header_mask"></div>
			<!--头部结束-->
			<!--开始搜索-->
			<div class="search_bg">
			    <div class="search">
			        <!--logo-->
			        <div class="logo_header">
						<img :src="topbarList.website_hander_logo" />
					</div>
			
			        <div>
			            <!--搜索框-->
			            <div style="display: flex;margin-left: 75px; line-height: 100px;">
                             <a-input-search v-model:value="input" style="width:400px;" placeholder="请输入商品名称~" enter-button="搜索"/>
			                <!-- <a-input style="width: 350px;margin-top: 0.5px;" v-model="input" placeholder="请输入商品名称~"></a-input>
			                <a-button class="search_bt" type="primary">搜索</a-button> -->
			            </div>
			            <!--搜索热词-->
			            <!-- <div class="words">
			                <p v-for="(item,index) in words" :key="index">{{item.cont}}</p>
			              </div> -->
			        </div>
			        <!-- 天气 -->
			        <div class="weather">
			            <div class="weather_top">
			                <div class="weather_cont">
			                    <p>
			                        <span>31°C</span>
			                        <span>2020年07月25日</span>
			                    </p>
			                    <p class="weather_img"><img src="http://farm.test.farmkd.com/sky_con/cloudy.png" alt="" /></p>
			                </div>
			                <div class="weather_data">
			                    <p>温度变化：28°C~33°C</p>
			                    <p>PM值：8</p>
			                    <p>风力：2级</p>
			                    <p>湿度：67.00%</p>
			                </div>
			            </div>
			        </div>
			    </div>
			</div>
			<!-- <i class="ri-close-circle-fill deleteicon" @click.stop="showDeleteWrapper(topbarList.id)"></i> -->
		</div>
        
		<!-- 样式二 -->
		<div class="topbarlist topbarb" v-if="topbarList.website_hander_type == 2">
			<div class="deanlogo">
				<a><img :src="topbarList.website_hander_logo"/></a>
			</div>
			<!-- 列表 -->
			<div class="deannav">
				<ul>
					<li v-for="(item,index) in topbarList.website_hander_list" :key='index' :class="indexactice == index?'active':''"><a>{{item.title}}</a></li>
				</ul>
			</div>
			<div style="display: flex;align-items: center;" v-if="topbarList.website_hander_register == 1">
				<div class="register">注册</div>
				<div class="login">登录</div>
			</div>
			<!-- <i class="ri-close-circle-fill deleteicon" @click.stop="showDeleteWrapper(topbarList.id)"></i> -->
		</div>
		
    </div>
</template>

<script>
export default {
    data() {
        return {
            input:'',
			indexactice: 0
        };
    },
    props: {
		topbarList:{
			type: Object,
			value: {}
		}
    },
    created() {
        
    },
    methods: {
        showDeleteWrapper(id){
        	this.$emit('changeDelet',id)
        },
		changeCurrent(){
			this.$emit('changeCurrent',-1)
		}
    },
    computed: {}
};
</script>

<style lang="scss">
#Topbar{
    color: #fff;
	margin-top: -2px;
	font-size: 13px;
	background: #fff;
	
	.topbarlist{
		position: relative;
		border: 1px solid #eee;
		cursor: pointer;
	}
	
	// .topbarlist:hover{
	// 	border: 1px dashed #409EFF;
	// }
	
	.deleteicon{
		position: absolute;
		bottom: 0px;
		right: 45px;
		z-index: 999;
		display: none;
		cursor: pointer;
		color: #409EFF;
		font-size: 25px;
	}
	
	// 样式一
	.topbara{
		.header_flexd{
		    position: fixed;
		    top: 0;
		    left: 0;
		    z-index: 99;
		}
		
		.header_mask{
		    width: 100%;
		    // height: 35px;
		}
		
		.header_pd a {
		    color: #fff;
		}
		
		.header_pd a:hover {
		    color: #67c23a;
		}
		
		.el-icon-arrow-down {
		    margin-left: 5px;
		}
		
		.User_Info {
		    position: relative;
		}
		
		.user_info {
		    position: absolute;
		    top: 35px;
		    left: 68px;
		    background: #fff;
		    z-index: 999;
		    border: 1px solid #eeeeee;
		    width: 215px;
		    height: 0px;
		    cursor: default;
		    border-top: none;
		    visibility: hidden;
		    /* transition: .2s all; */
		}
		
		.logout {
		    display: inline-block;
		    width: 100%;
		    text-align: right;
		    margin: 10px -15px 0;
		    color: #333;
		}
		
		.logout > span:hover {
		    color: #67c23a;
		    cursor: pointer;
		}
		
		.user_details {
		    display: flex;
		    height: 50px;
		    line-height: 25px;
		    padding: 0 20px;
		    color: #333;
		}
		
		.user_details img {
		    width: 50px;
		    height: 50px;
		    margin-right: 15px;
		    cursor: pointer;
		}
		
		/* .topbar .user_name:hover {
		    background: #fff;
		  } */
		
		.inherit_user {
		    visibility: inherit !important;
		    height: 120px !important;
		}
		
		/*头部*/
		.header {
		    width: 100%;
		    height: 35px;
		    line-height: 35px;
		    background: #353439;
		}
		
		.flex {
		    display: flex;
		}
		
		.header_wd {
		    width: 1200px;
		    margin: auto;
		}
		
		.header_pd {
		    display: flex;
		    justify-content: space-between;
		    align-items: center;
		}
		
		.header_pd > div {
		    display: flex;
		}
		
		.header_pd > div > div {
		    padding: 0 10px;
		    cursor: pointer;
		}
		
		.header_pd > div > div:hover {
		    color: #67c23a;
		}
		
		.header_pd > div:first-child > div:nth-child(1):hover {
		    cursor: default;
		    color: #fff;
		}
		
		.cart > .shop:hover {
		    color: #67c23a;
		}
		
		.topbar_phone:hover .phone {
		    display: block !important;
		}
		
		.shop {
		    color: #333;
		    padding: 0;
		    margin-bottom: 2px;
		}
		
		.phone {
		    position: absolute;
		    top: 35px;
		    right: -35px;
		    text-align: center;
		    display: none;
		    background: #fff;
		    z-index: 999;
		    transition: all .2s;
		
		    .phone_img{
		        border: 1px solid #ccc;
		        border-radius: 10px;
		
		        img {
		            padding: 10px 10px 0;
		            width: 100px;
		            height: 100px;
		        }
		
		        p{
		            line-height: 0;
		            color: #333;
		            padding: 0 0 20px;
		            cursor: default;
		        }
		    }
		}
		
		/*开始搜索*/
		.search_bg {
		    width: 100%;
		    background: #fff;
		    padding: 20px 0;
		}
		
		.search {
		    width: 1200px;
		    margin: auto;
		    display: flex;
			align-items: center;
		}
		
		.logo_header > img {
		    width: 205px;
		    height: 70px;
		    // margin-left: 30px;
		    cursor: pointer;
		}
		
		.search_bt {
		    height: 40px;
		    margin-top: 31px;
		    margin-left: 0px;
		    border-radius: 0;
		    font-size: 16px;
		    font-weight: 600;
		}
		
		input[type='text']:focus,
		select:focus {
		    border: 1px solid #67c23a;
		    outline: none;
		}
		
		.el-input__inner{
		    border-radius: 4px 0 0 4px;
		}
		
		.el-input__inner{
			height: 40px;
			line-height: 35px;
			width: 100%;
		}
		
		.words {
		    margin-left: 120px;
		    display: flex;
		    margin-top: -20px;
		}
		
		.words > p {
		    color: #a5a5a5;
		    margin-right: 10px;
		    cursor: pointer;
		}
		
		.words > p:hover {
		    color: #67c23a;
		}
		
		/* 天气 */
		.weather {
		    margin-left: 45px;
		    width: 100%;
		    margin-right: 25px;
		}
		
		.weather_cont {
		    line-height: 20px;
		    display: flex;
		    align-items: center;
		    justify-content: space-between;
		    font-size: 13px;
		}
		
		.weather_img {
		    width: 45px;
		    height: 35px;
		    margin-right: 10px;
		}
		
		.weather_img > img {
		    width: 100%;
		    height: 100%;
		}
		
		.weather_cont p {
		    color: #fcbc4e;
		}
		
		.weather_cont p > span:first-child {
		    margin-right: 20px;
		    font-size: 18px;
		}
		
		.weather_data {
		    display: flex;
		    align-items: center;
		    justify-content: space-between;
		    margin-top: 16px;
		    color: gray;
		    font-size: 12px;
		}
		
		/* 购物车 */
		.car_main {
		    position: relative;
		    width: 980px;
		    margin: 0px auto;
		    padding: 0px 0px;
		    min-height: 250px;
		}
		
		.car_head {
		    width: 100%;
		    height: 45px;
		    line-height: 45px;
		    color: #3c3c3c;
		    font-size: 14px;
		    padding: 0px;
		    border-bottom: 1px solid #dcdcdc;
		}
		
		.car_top {
		    width: 100%;
		    height: 50px;
		}
		
		.car_check_all {
		    position: relative;
		    width: 15%;
		    left: -86px;
		    float: left;
		    margin-top: 15px;
		}
		
		.car_information {
		    width: 45%;
		}
		
		.car_price {
		    width: 11%;
		}
		
		.car_number {
		    width: 10%;
		}
		
		.car_amount {
		    width: 11%;
		}
		
		.cart_empty {
		    position: absolute;
		    width: 100%;
		    top: 50%;
		    text-align: center;
		}
		
		.car_foot_bar {
		    position: absolute;
		    width: 55%;
		    height: 45px;
		    font-size: 14px;
		    color: #3c3c3c;
		    margin-top: 15px;
		}
		
		.car_foot_settle {
		    float: right;
		    width: 81%;
		}
		
		.piece {
		    float: left;
		    height: 45px;
		    line-height: 45px;
		}
		
		.totalMoney {
		    float: left;
		    margin-left: 20px;
		    height: 45px;
		    line-height: 45px;
		}
		
		.settle_accounts {
		    float: left;
		    margin-left: 20px;
		    height: 45px;
		    line-height: 45px;
		}
		
		.piece_num {
		    color: red;
		}
		
		.total_text {
		    color: red;
		    font-size: 18px;
		}
		
		.settle_button {
		    width: 100px;
		    height: 45px;
		    line-height: 45px;
		    border: none;
		    outline: none;
		    background-color: red;
		}
		
		.settle_button:disabled {
		    background-color: #eeefff;
		}
		
		.shop_info {
		    width: 100%;
		    height: 20px;
		    margin-top: 20px;
		    margin-bottom: 10px;
		    font-size: 12px;
		    align-items: center;
		    display: flex;
		}
		
		.shop_info_check_all {
		    position: relative;
		    width: 10px;
		    left: -20px;
		}
		
		.shop_info li a {
		    text-decoration: none;
		    margin-right: 10px;
		    color: #3c3c3c;
		}
		
		.shop_info li a:hover {
		    color: #67c23a;
		    text-decoration: underline;
		}
		
		.shop_icon {
		    width: 16px;
		    height: 16px;
		}
		
		.shop_discount {
		    width: 60px;
		    height: 20px;
		    margin-left: 10px;
		}
		
		.product {
		    width: 100%;
		    border: 1px solid #dcdcdc;
		    background: #f5f5f5;
		}
		
		.selected_styles {
		    background: lightgoldenrodyellow;
		}
		
		.product_details {
		    width: 100%;
		    height: 90px;
		    padding-left: 25px;
		}
		
		.product_description {
		    width: 100%;
		    height: 25px;
		    background: white;
		    font-size: 12px;
		    padding-left: 35px;
		    align-items: center;
		    display: flex;
		}
		
		.product_description_bg {
		    width: fit-content;
		    background: #eee;
		    height: 20px;
		    line-height: 20px;
		}
		
		.product_check {
		    float: left;
		    margin-top: 30px;
		    padding-top: 20px;
		    margin-right: 10px;
		    margin-left: -10px;
		}
		
		.product_img {
		    float: left;
		    padding-top: 20px;
		    width: 70px;
		    height: 70px;
		}
		
		.product_shop_info {
		    height: 70px;
		    float: left;
		    padding-left: 10px;
		    position: relative;
		    width: 180px;
		    padding-right: 20px;
		    padding-top: 20px;
		}
		
		.product_shop_name {
		    font-size: 12px;
		    text-align: left;
		}
		
		.product_shop_name a {
		    display: inline-block;
		    text-decoration: none;
		    letter-spacing: 1px;
		    color: #3c3c3c;
		    align-content: left;
		    width: 3rem;
		    overflow: hidden;
		    text-overflow: ellipsis;
		    white-space: nowrap;
		}
		
		.product_shop_name a:hover {
		    color: #67c23a;
		    text-decoration: underline;
		}
		
		.product_shop_icon {
		    position: absolute;
		    bottom: 0px;
		    width: fit-content;
		}
		
		.product_type_select {
		    width: 155px;
		    height: 20px;
		    line-height: 30px;
		    font-size: 12px;
		    position: relative;
		    color: #bebebe;
		    border: 1px dashed transparent;
		    text-align: left !important;
		}
		
		.product_type_all:first-child {
		    display: flex;
		    align-items: center;
		}
		
		.product_type {
		    width: 125px;
		    margin-bottom: 10px;
		}
		
		.product_edit {
		    width: fit-content;
		    position: absolute;
		    top: 0;
		    right: 0;
		}
		
		.product_edit_button {
		    color: white;
		    background: red;
		    border: none;
		    outline: none;
		}
		
		.product_price {
		    width: 105px;
		    float: left;
		    padding-top: 40px;
		    text-align: left;
		    padding-left: 5px;
		}
		
		.product_price_origin {
		    width: fit-content;
		    font-size: 14px;
		    color: grey;
		    text-decoration: line-through;
		}
		
		.product_price_current {
		    width: fit-content;
		    font-size: 14px;
		    font-weight: bold;
		}
		
		.product_number {
		    width: 90px;
		    float: left;
		    padding-top: 35px;
		    margin-left: -20px;
		}
		
		.product_number_content {
		    width: fit-content;
		    height: 24px;
		    background: #eee;
		}
		
		.product_number_button {
		    width: 24px;
		    float: left;
		    margin: 0px auto;
		    border: 1px solid transparent;
		}
		
		.product_number_button button {
		    width: 24px;
		    height: 24px;
		    outline: none;
		    background: transparent;
		    border: 1px solid transparent;
		}
		
		.product_number_button button:hover {
		    background: transparent;
		    border: 1px solid orange;
		}
		
		.product_number_input {
		    width: fit-content;
		    text-align: center;
		    float: left;
		    margin: 0px auto;
		}
		
		.product_number_input input {
		    width: 30px;
		    height: 24px;
		    text-align: center;
		    box-sizing: border-box;
		}
		
		.el-input__inner:hover {
		    border-color: #67c23a !important;
		}
		
		.el-input.is-active .el-input__inner,
		.el-input__inner:focus {
		    border-color: #67c23a !important;
		}
		
		.product_amount {
		    width: 100px;
		    float: left;
		    padding-top: 20px;
		}
		
		.product_total_price {
		    width: 100px;
		    font-size: 16px;
		    color: red;
		    font-weight: bold;
		    word-wrap: break-word;
		}
		
		.product_operation {
		    width: 100px;
		    float: left;
		    padding-top: 20px;
		    text-align: left;
		    margin-left: 20px;
		    line-height: 15px;
		    color: #3c3c3c;
		}
		
		.product_operation button {
		    width: fit-content;
		    border: none;
		    background: transparent;
		    outline: none;
		    font-size: 12px;
		}
		
		.product_operation button:hover {
		    color: #67c23a;
		    text-decoration: underline;
		}
	}
	
    // 样式二
	.topbarb{
		// width: 1200px;
		padding: 10px 0;
		margin: auto;
		display: flex;
		align-items: center;
		
		.deanlogo {
		    width: 200px;
		    height: 68px;
			
			img{
				width: 100%;
				height: 100%;
			}
		}
		
		.deannav {
		    width: 680px;
		    overflow: hidden;
		    margin-left: 70px;
		
		    >ul{
		        display: flex;
		        align-items: center;
		        justify-content: space-between;
		
		        li{
		            border-bottom: 3px solid transparent;
		            transition: all 0.2s linear;
		            cursor: pointer;
		        }
		
		        li>a{
		            color: #333;
		            font-size: 16px;
		            height: 50px;
		            line-height: 50px;
		        }
		
		        .active{
		            border-bottom: 3px solid #ec6e17;
		        }
		
		        li:hover{
		            border-bottom: 3px solid #ec6e17;
		            transform: scale(1);
		        }
		    }
		}
		
		// 注册登录
		.register{
		    border: 2px solid #ef6e1c;
		    color: #ef6e1c;
		    padding: 8px 18px;
		    border-radius: 8px;
		    font-size: 14px;
		    cursor: pointer;
		    transition: all .4s;
		}
		
		.register:hover{
		    background: #ef6e1c;
		    color: #fff;
		}
		
		.login{
		    background: #ef6e1c;
		    color: #fff;
		    padding: 10px 20px;
		    border-radius: 8px;
		    font-size: 14px;
		    margin-left: 20px;
		    cursor: pointer;
		    transition: all .4s;
		}
		
		.login:hover{
		    background: #f79557;
		}
	}
}
</style>
